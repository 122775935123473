@import '../../../variables.scss';

#snackbar {
  display: flex;
  align-items: center;
  max-width: 400px;
  min-width: 250px; /* Set a default minimum width */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1200; /* Add a z-index if needed */
  right: 7.5%; /* Center the snackbar */
  top: 110px;
  color: var(--black-1);
  background-color: var(--white-1);
  box-shadow: 0 4px 8px 0 var(--black-5), 0 6px 20px 0 var(--black-5);
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.8s;
  animation: fadein 0.5s, fadeout 0.5s 2.8s;
}

.snackbar-message {
  flex-grow: 1;
}

.snackbar-icon {
  width: 22px;
  padding: 5px 10px 5px 0px;
}

.snackbar-close {
  padding: 5px;
  margin-left: 50px;
  background-color: transparent;
  border: none;
}

.success-alert {
  border-left: 4px solid #43a047;
}

.error-alert {
  border-left: 4px solid #d32f2f;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {right: 0; opacity: 0;}
  to {right: 7.5%; opacity: 1;}
}

@keyframes fadein {
  from {right: 0; opacity: 0;}
  to {right: 7.5%; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {right: 7.5%; opacity: 1;}
  to {right: 0; opacity: 0;}
}

@keyframes fadeout {
  from {right: 7.5%; opacity: 1;}
  to {right: 0; opacity: 0;}
}

@media (max-width: $small-grid-breakpoint) {
  #snackbar {
    top: auto;
    right: auto;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 0; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 0; opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 0; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 0; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

}