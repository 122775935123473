@import './variables.scss';

:root {
  --primary-1: #233d66;
  --primary-2: #325486;
  --primary-3: #426daf;
  --primary-4: #8eaad4;
  --primary-5: #f7f9fc;
  --white-1: #ffffff;
  --white-2: #f2f2f2;
  --white-3: #e4e4e4;
  --white-4: #d7d7d7;
  --white-5: #cacaca;
  --black-1: #2e2e2e;
  --black-2: #808080;
  --black-3: #9e9e9e;
  --black-4: #bebebe;
  --black-5: #dedede;
}

body {
  margin: 0;
  background-color: var(--primary-5);
  font-family: Brandon Grotesque;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.m0 {
  margin: 0px !important;
}

.m10 {
  margin: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mtb10 {
  margin: 10px 0px;
}

.m20 {
  margin: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mtb20 {
  margin: 20px 0px;
}

.p0 {
  padding: 0px !important;
}

.p20 {
  padding: 20px !important;
}

.pt20 {
  padding-top: 20px;
}

.ptb20 {
  padding: 20px 0px;
}

.pl10 {
  padding-left: 10px;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: auto !important;
}

.font-small {
  font-size: 11px !important;
}

.selected-image {
  max-width: 80%;
  margin: auto;
  margin-top: 20px;
}
