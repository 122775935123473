@import '../../../variables.scss';

.nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  max-width: 85%;
  margin: auto;
  padding: 10px 0px;
}

.title {
  flex-grow: 1;
}

.nav-logo {
  max-width: 215px;
  flex-grow: 1;
  vertical-align: middle;
}

.nav-items-container {
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  margin: 0px 20px;
  padding: 10px 0px;
  text-decoration: none;
  color: var(--primary-1);
  position: relative;
  &:hover {
    border-bottom: 2px solid var(--primary-1);
  }
  &:last-child {
    margin-right: 0px;
  }
}

.nav-dropdown {
  margin: 0px 20px;
  text-decoration: none;
  color: var(--primary-1);
  position: relative;
  &:hover {
    border-bottom: 2px solid var(--primary-1);
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.active {
  border-bottom: 2px solid var(--primary-1);  
}

.nav-item--dropdown {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
}

.nav-item-chevron--down {
  transition: transform 0.5s;
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--primary-1);
}

.nav-item--dropdown-content {
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 40px;
  width: 100%;
  box-shadow: 1px 5px 4px -1px var(--primary-1);
  transition: max-height 0.5s;
  display: flex;
  flex-direction: column;
}

.show-dropdown {
  max-height: 600px;
}

.nav-dropdown-item {
  text-decoration: none;
  color: var(--primary-1);
  padding: 10px;
  background-color: var(--white-1);
  cursor: pointer;
  &:hover {
    background-color: var(--primary-1);
    color: var(--white-1);
  }
}

.dropdown-active {
  background-color: var(--primary-1);
  color: var(--white-1);
}

.hamburger-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 25px;
  color: var(--primary-1);
  font-size: 25px;
  cursor: pointer;
}

@media (max-width: $small-grid-breakpoint) {
  .nav-container {
    flex-direction: column;
    max-width: 100%;
    padding-bottom: 0px;
  }

  .nav-logo {
    max-width: 150px;
  }

  .title {
    padding-bottom: 20px;
  }

  .nav-items-container {
    flex-direction: column;
    width: 100%;
    max-height: 0px;
    transition: max-height 0.5s;
    overflow: hidden;
  }

  .show {
    max-height: 600px;
    transition: max-height 1s;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    margin: 0px;
    &:hover {
      background-color: var(--primary-1);
      color: var(--white-1);
      border-bottom: none;
    }
  }

  .nav-dropdown {
    width: 100%;
    text-align: center;
    margin: 0px;
    &:hover {
      border-bottom: none;
    }
  }

  .nav-item--dropdown {
    &:hover {
      background-color: var(--primary-1);
      color: var(--white-1);
      border-bottom: none;

      .nav-item-chevron--down {
        border-top: 10px solid var(--white-1);
      }
    }
  }

  .nav-item-chevron--down {
    position: absolute;
    right: 20px;
  }

  .nav-item--dropdown-content {
    position: relative;
    box-shadow: none;
    top: 0;
  }

  .show-dropdown {
    position: relative;
    color: var(--primary-1);
    top: 0;
    max-height: 600px;
  }

  .active {
    background-color: var(--primary-1);
    color: var(--white-1);
    border: none;
  }
}

@media (max-width: $medium-grid-breakpoint) {
  .nav-logo {
    max-width: 180px;
  }
}