@import "../../../variables.scss";

.tooltip {
    border-bottom: 1px dotted var(--primary-1);
  }
  
  .tooltip-content {
    visibility: hidden;
    width: 120px;
    background-color: var(--primary-1);
    color: var(--white-1);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    top: 20%;
    right: 115%;
    z-index: 1;
    &::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 100%; /* To the right of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent var(--primary-1);
    }
  }
  
  .tooltip:hover .tooltip-content {
    visibility: visible;
  }