@import '../../../variables.scss';

.footer-container {
  background-color: var(--primary-1);
  color: var(--white-1);
  font-size: 14px;
  line-height: 1.5;
}

.footer-static-links {
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-link {
    padding: 10px;
    padding-bottom: 0px;
  }
}

.footer-details {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    padding-right: 20px;
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  max-width: 85%;
  margin: auto;
}

.footer-links-title {
  flex: 15%;
  font-size: 16px;
  min-width: 180px;
  // padding: 10px;
  // padding-left: 0px;
}

.footer-links-description {
  // padding: 10px;
  flex: 85%;
}

.footer-links-section {
  display: flex;
  margin: 20px 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  align-items: baseline;
  // &:first-child {
  //   padding-left: 7.5%;
  // }
  // &:last-child {
  //   padding-right: 7.5%;
  // }
}

.footer-link {
  margin: 10px 0px;
  text-decoration: none;
  color: var(--white-1);
}

.pb20 {
  padding-bottom: 20px;
}

.social-logo {
  width: 24px;
  height: 24px;
}

@media (max-width: 767px) {
  .footer-links {
    max-width: 100%;
  }
  .footer-links-section {
    flex-direction: column;
    margin: 20px 2%;
    padding: 0px 2.5%;
  }
  .footer-static-links {
    flex-direction: column;
    align-items: flex-start;
    padding: 2.5%;
    .footer-link {
      padding: 2.5%;
      margin: 0px;
    }
  }
}