@import './variables.scss';

.main-container {
  min-height: 80vh;
}

.fixed-logo {
  width: 48px;
  height: 48px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 black;
  cursor: pointer;
  &.left {
    left: 30px;
  }
}

.terms-and-conditions-container,
.privacy-policy-container,
.about-us-container {
  max-width: 85%;
  margin: auto;
  padding-bottom: 20px;
  color: var(--primary-1);
  text-align: justify;
  ol {
    padding-inline-start: 12px;
  }
}
